import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { EventNodeImage } from "../../../components/Image";

const TimelineEvent = ({ eventData }) => {
  const eventTime = eventData.time.split(" ")[1].substring(0, 5);
  const eventDescription = eventData.description;
  const eventCity = eventData.city ? eventData.city : "";
  const postalCode = eventData.postalCode ? eventData.postalCode : "";
  const province = eventData.province ? eventData.province : "";
  const eventProvincePostalCode = `${province} ${postalCode}`;
  const eventCountry = "country" in eventData ? eventData.country : "";

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {useMediaQuery(useTheme().breakpoints.up("lg")) ? (
        <TimelineItem sx={{ mr: 100 }}>
          <TimelineOppositeContent
            sx={{ m: "auto 16px auto 0", p: 0 }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Typography
              variant="h4"
              color="black.main"
              sx={{
                alignSelf: { xs: "flex-start", lg: "flex-end" },
                justifySelf: "flex-start",
              }}
            >
              {eventTime}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: "75px" }} />
            <EventNodeImage sx={{ height: "18px", width: "18px" }} />
            <TimelineConnector sx={{ height: "75px" }} />
          </TimelineSeparator>
          <TimelineContent sx={{ pt: 3, pb: 3, px: 2 }}>
            {/* Event Info - Light */}
            <Stack
              position={{ xs: "inherit", lg: "relative" }}
              top={{ xs: "inherit", lg: 25 }}
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-end"
            >
              <Typography variant="h3" color="black.main">
                {eventDescription}
              </Typography>
              <Stack direction="column">
                <Typography variant="h4" color="black.main">
                  {eventCity}
                </Typography>
                <Typography variant="h4" color="black.main">
                  {eventProvincePostalCode}
                </Typography>
                <Typography variant="h4" color="black.main">
                  {eventCountry}
                </Typography>
              </Stack>
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ) : (
        <Grid container py={2}>
          <Grid item xs={12}>
            <Typography variant="h4" color="black.main">
              {eventTime}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="black.main">
              {eventDescription}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column">
              <Typography variant="h4" color="black.main">
                {eventCity}
              </Typography>
              <Typography variant="h4" color="black.main">
                {eventProvincePostalCode}
              </Typography>
              <Typography variant="h4" color="black.main">
                {eventCountry}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TimelineEvent;
